var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { staticClass: "card-link", attrs: { id: _vm.idCard, to: _vm.to } },
    [
      _c("div", { class: _vm.classes }, [
        _vm.position
          ? _c("div", { staticClass: "card-news-img" }, [
              _c("img", {
                staticClass: "card-news-img__image",
                attrs: { src: _vm.img, alt: _vm.title },
              }),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "card-news__content card-content" },
          [
            _c("div", { staticClass: "card-content__date" }, [
              _vm._v(" " + _vm._s(_vm.date) + " "),
            ]),
            _c("div", { staticClass: "card-content__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _vm.shortDescription
              ? _c("div", { staticClass: "card-content__description" }, [
                  _vm._v(" " + _vm._s(_vm.shortDescription) + " "),
                ])
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "card-content__link",
                attrs: { id: _vm.idCard, to: _vm.to },
              },
              [_vm._v(" Подробнее ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }