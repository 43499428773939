<template>
  <router-link :id="idCard" class="card-link" :to="to">
    <div :class="classes">
      <div v-if="position" class="card-news-img">
        <img class="card-news-img__image" :src="img" :alt="title" />
      </div>
      <div class="card-news__content card-content">
        <div class="card-content__date">
          {{ date }}
        </div>
        <div class="card-content__title">
          {{ title }}
        </div>
        <div v-if="shortDescription" class="card-content__description">
          {{ shortDescription }}
        </div>
        <router-link :id="idCard" class="card-content__link" :to="to">
          Подробнее
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CardNews",
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    idCard: {
      type: String,
      default: "",
    },
    position: {
      type: String,
    },
    img: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    shortDescription: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
      require: true,
    },
  },
  computed: {
    classes() {
      return {
        "card-news": true,
        "card-news_shadow": this.shadow,
        "card-news_img-top": this.position === "top" ? true : "",
        "card-news_img-left": this.position === "left" ? true : "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-link {
  text-decoration: none;
}
.card-news {
  position: relative;
  background: #fff;
  min-height: 100%;

  &-img {
    &__image {
      object-fit: cover;
      object-position: top;
    }
  }

  &_shadow {
    background: #f9f9f9;
    box-shadow: 4px 5px 12px rgba(0, 0, 0, 0.1);
  }

  &_img-top {
    @media screen and (min-width: $screen-mini) {
      display: flex;
    }
    @media screen and (min-width: $screen-lg) {
      display: block;
    }
    .card-news {
      &-img {
        height: 235px;

        @media screen and (min-width: $screen-mini) {
          width: 40%;
        }

        @media screen and (min-width: $screen-lg) {
          width: inherit;
        }
      }
      &__content {
        @media screen and (min-width: $screen-mini) {
          width: 60%;
        }

        @media screen and (min-width: $screen-lg) {
          width: inherit;
        }
      }
    }
  }

  &_img-left {
    @media screen and (min-width: $screen-mini) {
      display: flex;
    }
    .card-news {
      &-img {
        height: 228px;

        @media screen and (min-width: $screen-mini) {
          width: 40%;
        }
      }

      &__content {
        @media screen and (min-width: $screen-mini) {
          width: 60%;
        }
      }
    }
  }

  &-img {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    font-size: 12px;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (min-width: $screen-sm) {
      padding: 30px;
    }
  }

  .card-content {
    &__date {
      font-size: 14px;
      color: #8c8c8d;
      margin-bottom: 6px;
    }

    &__title {
      font-size: 20px;
      color: #18181b;
      margin-bottom: 9px;
      overflow: hidden;
      line-height: 1.2;
      max-height: 48px;
    }

    &__description {
      font-family: Rubik;
      font-weight: 300;
      color: #5d5d5f;
      line-height: 1.5;
      margin-bottom: 26px;
      overflow: hidden;
      max-height: 54px;
    }

    &__link {
      font-family: Rubik;
      position: absolute;
      bottom: 20px;
      color: #18181b;
      font-weight: 300;

      @media screen and (min-width: $screen-sm) {
        bottom: 30px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
